<template>
  <svg
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 8C9.46957 8 8.96086 8.21071 8.58579 8.58579C8.21071 8.96086 8 9.46957 8 10V37.1716L12.5858 32.5858C12.9609 32.2107 13.4696 32 14 32H38C38.5304 32 39.0391 31.7893 39.4142 31.4142C39.7893 31.0391 40 30.5304 40 30V10C40 9.46957 39.7893 8.96086 39.4142 8.58579C39.0391 8.21071 38.5304 8 38 8H10ZM5.75736 5.75736C6.88258 4.63214 8.4087 4 10 4H38C39.5913 4 41.1174 4.63214 42.2426 5.75736C43.3679 6.88258 44 8.4087 44 10V30C44 31.5913 43.3679 33.1174 42.2426 34.2426C41.1174 35.3679 39.5913 36 38 36H14.8284L7.41421 43.4142C6.84222 43.9862 5.98198 44.1573 5.23463 43.8478C4.48728 43.5382 4 42.8089 4 42V10C4 8.4087 4.63214 6.88258 5.75736 5.75736Z"
    />
  </svg>
</template>
